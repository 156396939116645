.heading{
    padding: 30px 15px 0 15px;
    color: #004065;
    margin-top: 1rem;
    h1{
        font-size: 20px;
        margin-bottom: 0.5rem;
    }
    .subtitle{
        font-size: 15px;
        color: #596a94;
        margin-bottom: 20px;
        
    }

    .description{
        font-size:15px;
        margin-bottom: 20px;

    }
}