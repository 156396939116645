.q10__block__boxes{
    display: grid;
    place-content: center;
    width: 100%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;   
    .p-0 {
        padding: 0;
    }
    &__description{
        margin: 2rem 15px;
        @media (min-width: 768px){
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }
        ul {
            list-style-type: none;
    
        }
        ul li{
            border-bottom: 1px solid #efefef;
            padding: 1rem 0;
            margin-left:2rem;
        }
        ul ul li{
            margin:0;
        }
    }
}

.q10__block__boxes__content__view {
   // padding-bottom: 3rem;
    &__title {
        text-align: center;
        margin: 1rem 0;
        font-size: 28px;
        line-height: 35px;
        text-transform: none;
        letter-spacing: -1px;
        color: #223972;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &__subtitle {
        text-align: center;
        margin: 1rem 0;
        color: #333333;
        font-size: 14px;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &__items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        padding-top: 3rem;
        align-items: center;

        @media (min-width: 1200px){
            justify-content: space-between;
            gap: 2.7rem;
        }

        &__item {
            width: 94%;
            height: 300px;
            text-align: center;
            background: #162015;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            font-size: 16px;
            border: 1px solid #dcdfff;
            background: #ffffff;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            position: relative;
            top: 0;
            transition: top ease 0.5s;
            &__title{
                color: #002e5b;
            }
            &__subtitle{
                font-size: 14px;
                color: #818181;
                padding:1rem;
            }
            &__link{
                position: relative;
                padding: 7px 0 4px 0;
                font-size: 12px;
                text-transform: capitalize;
                margin-top: 0px;
                color: #223972;
                align-items: center;
                justify-content: start;
                display: flex;
                margin: 0 26px 20px 26px;
                padding-left: 5px;
                background-color: #004065;
                color: #fff;
                text-decoration: underline;
                font-size: 20px;
                // .icon{
                //     color: #223972;
                //     margin-left: 0.5rem;
                // }
                @media (min-width: 1200px){
                    margin: 0 16px 10px 16px;
                    font-size: 20px;
                }
                @media (min-width: 1500px){
                    font-size: 24px;
                }
                @media (min-width: 1800px){
                    font-size: 24px;
                }
                // @media (min-width: 2400px){
                //     font-size: 28px;
                // }
            }
            &__image{
                top:0;
                position: absolute;                
                padding:1rem;
                .selectedIcon{
                    width: 65px;
                    height: 65px;
                }
            }
            // @media (min-width: 768px) {
            //     width: 500px;
            //     height: 300px;
            // }
            @media (min-width: 768px) {
                width: 47%;
                height: 300px;
            }

        }
        &__item:hover{
            top: -10px;
        }
    }
}


.addAnimationToArrows{
    position: relative;
    animation: linear;
    animation-name: arrowAninmation;
    animation-duration: 0.8s;
}
    @keyframes arrowAninmation {
        0% {
        left: 0px;
        }
        20% {
        left: 10px;
        }
        40% {
        left: -5px;
        }
        60% {
        left: 7px;
        }
        80% {
        left: -2px;
        }
        100% {
        left: 0px;    
        }
    }