.about_us{
    padding: 0 15px;
    @media (min-width: 1025px){
        padding: 0 80px;
        .contentBody{
            // display: flex;
            // flex-direction: row;
        }
    }
    .logo_title{
        width: 150px;
        height: 150px;
    }
    margin-bottom: 15px;
    color: #004065;
    margin-top: 2rem;
    .our_story_title{
        font-weight: 700;
        font-size: 22px;
        @media (min-width: 1025px){
            font-size: 20px;
        }
        @media (min-width: 1200px){
            font-size: 20px;
        }
        @media (min-width: 1500px){
            font-size: 22px;
        }
        @media (min-width: 1800px){
            font-size: 24px;
        }
        // @media (min-width: 2400px){
        //     font-size: 36px;
        // }
    }
    .our_story_title:nth-child(2){
       margin-right: -10%;
    }
    .left{
        @media (min-width: 1025px){
            display: flex;
            flex-direction: row-reverse;
        }
        .our_story_section_description{
                display: flex;
                flex-direction: column;
                justify-content: center;
        }
    }
    .right{
        width: 100%;
        @media (min-width: 1025px){
            display: flex;
        }
        .our_story_section_description{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
       }
    }
    .center{
        width: 100%;
    }
    .our_story_image_center{
        margin-top:1rem;
        width: 100% !important;
        .image{
            width: 100%;
        }
    }
    .our_story_image{
        margin-bottom: 15px;

        @media (min-width: 1025px){
            margin: 0 20px 20px 0;
            margin: 35px;
        }

        img{
            width: 100%;
            @media (min-width: 1025px){
                width: 800px;
            }
            @media (min-width: 1800px){
                width: 900px;
            }
        }

    }
    .wysiwyg{
        // margin-bottom: 15px;
        // width: 10px;
        display: block;
        @media (min-width: 1025px){
            font-size: 12px;
        }
        @media (min-width: 1200px){
            font-size: 16px;
        }
        @media (min-width: 1500px){
            font-size: 18px;
        }
        @media (min-width: 1800px){
            font-size: 20px;
        }
        // @media (min-width: 2400px){
        //     font-size: 30px;
        // }
    }
    .our_story_div{
        width: 100%;
        display: flex;
        flex-direction: row;
    }

}