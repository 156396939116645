.post_category{
    padding: 0;
    @media (min-width: 990px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;   
    }
    &_blocks{
        width: 100%;
        @media (min-width: 990px) {
            width: 80%;
        }
    }
    &_blocks100{
        width: 100%;
        @media (min-width: 990px) {
            width: 100%;
        } 
        .redesignToRow{
            @media (min-width: 1025px){
                display: flex;
                flex-direction: row;
                img{
                    width: 50%;
                }
                &__allTexts{
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    padding: 0 35px 0 0px;
                }

            }
            &__date_with_author{
                display: flex;
                margin: 10px 0px 25px 15px;
                padding: 0px;
                color: #004065;
                @media (min-width: 1025px){
                    padding: 0 20px;
                }

            }
        }
        &__carouselReadMore{
            &__title, &__lastParagraph{
                padding: 0 15px;
                color: #004065;
                @media (min-width: 1025px){
                    padding: 0 35px;
                }
                // @media (min-width: 1800px){
                //     // padding: 0 90px;
                //     padding: 0 120px 0 0;
                // }
                // @media (min-width: 2400px){
                //     padding: 0 120px 0 0;
                // }
            }
            &__title{
                font-family: "Roboto", sans-serif;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 10px;
                @media (min-width: 1025px){
                    margin-top: 40px;
                    margin-bottom: 10px;
                }
                // @media (min-width: 2400px){
                //     font-size: 1.6vw;
                // }
            }
            &__subtitle{
                font-size: 18px;
                font-style: italic;
                // @media (min-width: 2400px){
                //     font-size: 1vw;
                // }

            }
            
            &__subtitle:not(:last-child):after{
                content: '|';
                margin: 0.3rem;
            }
            &__paragraph{
                font-size: 18px;
                font-style: italic;
            }
            &__videoBox{
                width: 100%;
                @media (min-width: 1025px){
                    padding: 35px;
                }
                // @media (min-width: 1800px){
                //     // padding: 0 90px;
                //     padding: 0 0 0 120px;
                // }
                // @media (min-width: 2400px){
                //     padding: 0 0 0 120px;
                // }
            }
            &__lastParagraph {
                margin: 5px 0 15px 0;
                font-family: "Roboto", sans-serif !important;
                font-size: 18px;
                line-height: 24px;
                // @media (min-width: 2400px){
                //     font-size: 1vw;
                // }
                
                p {
                    font-family: "Roboto", sans-serif !important;
                    & > * {
                    font-family: "Roboto", sans-serif !important;
                    }
                }
            }
        }
    }
    .title{
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .child_title{
        font-size: 20px;
        font-weight: 500;
        color:#000;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .content{
        margin-left:2rem;
        &_box{
            margin-bottom: 2rem;
            &_child{
                margin-left:2rem;
            }
        }
    }
    &_list{
        width: 100%;
        &_title{
            margin-bottom: 1rem;
            font-size: 20px;
            font-weight: bold;
        }
        &_box{
            overflow: unset;
            width: 100%;
            &_categories{
                width: 100%;
                display: flex; 
                flex-direction: column;
                &_item{
                    background: #f2f2f2;
                    padding: 1rem;
                    a {
                        text-decoration: none;
                        color: #223972;
                    }
                }
            }
            // @media (min-width: 768px) {
            //     overflow: hidden;
            //     width: 250px;
            // }
            @media (min-width: 990px) and (max-width: 1200px) {
                overflow: hidden;
                width: 200px;
            }
        }
        @media (min-width: 990px) {
            width: 20%;
            margin-left: 15px;
            position: sticky;
            height: 100%;
            top: 0;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;    
        gap:2rem;
    }
    ul {
        list-style-type: none;

    }
    ul li{
        border-bottom: 1px solid #efefef;
        padding: 1rem 0;
    }
    ul ul li{
        margin:0;
    }
    .post_category_child{
        margin-top: 2rem;
    }
}

.selected{
    border-left: 5px solid #10aedf;
}
