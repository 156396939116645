.legal_information{
    margin-top: 2rem;
    padding: 0 15px;
    @media (min-width: 1025px){
        margin: 20px 65px 20px 65px;
    }
    @media (min-width: 1800px){
        margin: 40px 65px;
    }
   
&_title{
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    text-transform: none;
    letter-spacing: -0.4px;
    color: #2680EB;
    text-decoration: underline;
    cursor: pointer;
    @media (min-width: 2400px){
        font-size: 1.5vw;
        padding: 15px;
    }
}
    &_title:after{
        content:' >';
    }
}