.q10__content__slider {
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding: 0px;
    @media (min-width: 768px) {
        padding: 0 80px;
        width: 80%;
        margin-bottom: 6.3rem;
    }
    .arrow {
        position: absolute;
        width: 45px;
        height: 45px;
        top: 35%;
        left: auto;
        right: auto;
        transform: translate(-50%);
        z-index: 100;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        background-color: #0F4F7E;
        border: 2px solid #7495C3;
        @media (min-width: 1800px){
            width: 65px;
            height: 65px;
        }

        &.arrow-left {
            cursor: pointer; 
            left: 40px;
            @media (min-width: 768px) {
                left: 25px;
                top: 180px;
            }
            @media (min-width: 1025px) {
                left: 70px;
                top: 250px;
            }
            @media (min-width: 1500px) {
                left: 70px;
                top: 200px;
            }
            @media (min-width: 1800px) {
                left: 65px;
                top: 225px;
            }
            @media (min-width: 2400px) {
                top: 250px;
                left: 90px;
            }
            .arrowLeft{
                width: 20px;
                height: 20px;
                position: relative;
                right: -12px;
                bottom: -11px;
                @media (min-width: 1800px) {
                    width: 40px;
                    height: 40px;
                }
            }
          
        }

        &.arrow-right {
            cursor: pointer;
            right: -3px;
            @media (min-width: 768px) {
                right: 25px;
            }
            @media (min-width: 1025px) {
                right: 25px;
                top: 250px;
            }
            @media (min-width: 1500px) {
                right: 25px;
                top: 200px;
            }
            @media (min-width: 1800px) {
                right: 5px;
                top: 225px;
            }
            @media (min-width: 2400px) {
                top: 250px;
                right: 25px;
            }
            .arrowRight{
                width: 20px;
                height: 20px;
                position: relative;
                right: -12px;
                bottom: -12px;
                @media (min-width: 1800px) {
                    width: 40px;
                    height: 40px;
                }
            
            }
        }
    }

    &__title {
        text-align: start;
        padding: 15px 15px;
        font-size: 22px;
        color: #004065;
        @media (min-width: 768px) {
            margin-bottom: 5rem;
        }
        @media (min-width: 2400px) {
            font-size: 34px;
        }
    }
    &__carousel {
        width: 100%;
        overflow: hidden;
        &__box {
            @media (min-width: 1025px){
                height: 560px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .setStaticHeight{
                // height: 400px;
                height: 228px;
                display: flex;
                // align-items: center;
                justify-content: center;
            }
            &__images {
                flex-direction: row;
                display: flex;
                // justify-content: center;
                // align-items: center;
                margin: 20px 50px;
                @media (min-width: 1025px){
                    margin: 5px 10px;
                    // height: 400px;
                    // max-height: 400px;
                }
                .image {
                    width: 100%;
                    // margin: auto;
                    max-height: 400px;
                }
            }
            &__title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: start;
                font-size: 24px;
                color: #004065;
                font-weight: 700;
                padding-left: 26px;
                line-height: 1.5rem;
                height: 50px;
                @media (min-width: 768px) {
                    font-size: 55px;
                    font-weight: 500;
                }
                @media (min-width: 1025px) {
                    font-size: 25px;
                }
                @media (min-width: 1500px) {
                    font-size: 25px;
                    margin-top: 0.5rem;
                }
                &__date{
                    font-size: 20px;
                    font-style: italic;
                    color: #004065;
                    font-weight: 500;
                    padding: 15px 26px 15px 26px;
                    margin-top: 1rem;
                    
                    @media (min-width: 1025px){
                        font-size: 1.5vw;
                        padding: 5px 16px 5px 16px;
                    }
                    @media (min-width: 1200px){
                        font-size: 20px;
                    } 
                    @media (min-width: 1500px){
                        font-size: 22px;
                    } 
                    @media (min-width: 1800px){
                        font-size: 24px;
                    }
                    // @media (min-width: 2400px){
                    //     font-size: 26px;
                    // }
                }
            }
            
            &__description {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 50px;
                font-size: 20px;
                text-align: justify;
            }
        }
        
    }
}
