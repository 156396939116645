.q10__content{
    .q10__photoDisplay{
        
        &__sliderHomepage{
            margin: 0 auto;
            position: relative;
            width: 100%;
            padding: 0px;
            .singleImageSlider{ 
                color: red; 
                width: 100%;
                height: 200px;
                @media (min-width: 1025px){
                    height: 659px;
                }
                .imageSlider{ 
                    width: 100%;
                    height: 85%;
                    @media (min-width: 1025px){
                        height: 100%;
                        height: 659px;
                    }
                    
                    
                }
            }
        }




        &__downImages{
         @media (max-width: 1024px){
          display: flex;
          flex-wrap: wrap;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          position: relative;
          top: -30px;
        //   z-index: 101;
          background-color: #fff;
          margin: 0 15px;
          .singleImageDiv{
            padding: 15px;
            width: 50%;
            .singleImage{
                width: 100%;
            }
            .singleText{
                text-align: center;
                text-decoration-color: #004065;
                color: #004065;
                color: blue;
                font-size: 14px;
                font-family: 'Roboto', sans-serif;
            }
            }
        }
            @media (min-width: 1025px){
                display: flex;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                background-color: #fff;
                position: relative;
                // margin: 0 10%;
                width: 100%;
                margin-top: -75px;
                margin-bottom: 20px;

                .singleImageDiv{
                    text-align: center;
                    padding: 15px;
                    width: 16.67%;
                    .singleImage{
                        width: 100%;
                    }
                    .singleText{
                        text-align: center;
                        text-decoration-color: #000;
                        color: #000;
                        color: blue;
                    }
                }
            }
            @media (min-width: 1200px){
                width: 85%;
                display: flex;
                justify-content: center;
                margin: 0 auto;
                margin-top: -75px;
                margin-bottom: 20px;
                .singleImageDiv{
                    padding: 30px;
                    .singleText{
                       font-size: 14px;
                    }
                }
            }
            @media (min-width: 1500px){
                width: 75%;
            }
            @media (min-width: 1800px){
                width: 58%;
                .singleImageDiv{
                    padding: 20px;
                    .singleText{
                    //    font-size: 18px;
                    }
                }
            }
            @media (min-width: 2400px){
                width: 46%;
                .singleImageDiv{
                    padding: 20px;
                    .singleText{
                    //    font-size: 18px;
                    }
                }
            }
          
        }
    }
}