@font-face {
    font-family: "Roboto";
    src:
        url("../../fonts/front/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: 
        url("../../fonts/front/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
