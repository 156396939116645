.q10__block_downloads {
    min-height: calc(100vh - var(--footer_height) - var(--header_height));

    display: grid;
    place-content: center;
    .p-0 {
        padding: 0;
    }
}

.q10__content__block__content__downloads {
    &__title {
        text-align: center;
        font-size: 36px;
        margin: 1rem 0;
        color: #162015;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &__subtitle {
        text-align: center;
        margin: 1rem 0;
        color: #162015;
        font-size: 20px;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &__items {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 1rem;
        &__item {
            width: 119px;
            height: 116px;
            text-align: center;
            background: #064266;
            border-radius: 1rem;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            font-weight: bold;
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;
            @media (min-width: 768px) {
                width: 159px;
                height: 156px;
            }
        }
        .step{
            overflow-wrap: break-word;
            width: 100%;
        }
    }

}
