.q10__content{
    #job_form{
        .img{
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #3574A9;
            img{
                height: auto;
                width: 100%;
            }
        }
        .confirm_text{
            text-align: start;
            padding: 15px 15px;
            font-size: 22px;
            color: #004065;  
            
        }
        .arrow_left{
            width: 7px;
            margin: 0 3px 0 3px;
        }

        .addMarginLeftTight{
            margin: 0 15px;
            @media (min-width: 1025px){
                margin: 0 80px
            }
            @media (min-width: 1800px){
                margin: 0 80px 50px 80px;
            }
            @media (min-width: 2400px){
                margin: 0 80px 20px 80px;
            }
           
            .titleJobApplication{
                padding: 15px 0;
                font-size: 25px;
                font-weight: 700;
                color: #004065;
            }
            // .paraJobApplication{
            //     padding: 15px 0;
            //     color: #004065;
            // }
            p{
                // padding: 15px 0;
                color: #004065;
            }
            .subtitleJobApplication{
                padding: 15px 0;
                font-size: 25px;
                font-weight: 700;
                color: #004065;
            }
            .divJobApplication{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin: 15px 0;
                @media (min-width: 1025px){
                    width: 35%;
                }
               
                .titleJob{
                    background-color: #004065;
                    color: #fff;
                    width: 75%;
                    text-align: center;
                    border-radius: 2px 0 0 2px;
                    padding: 10px;
                    font-size: 18px;
                }
                .buttonJob{
                    background-color: #C1922D;
                    color: #fff;
                    text-decoration: underline;
                    width: 25%;
                    text-align: center;
                    border-radius: 0 2px 2px 0;
                    padding: 10px;
                    font-size: 18px;
                }
            }
        }
        .q10__content__block{
            &__content{
                &__form{
                    &__group{
                        align-items: start;
                        padding: 0 !important;
                        @media (min-width: 1025px){
                            display: flex;
                            flex-direction: column;
                        }
                        &__descriptionJob{
                            font-size: 18px;
                            color: #004065;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;
                        }
                        .inputDiv{
                            width: 100%;
                            // input:last-child{
                            //    display: none;
                            // }
                        }
                        &__input{
                            width: 100%;
                            background-color: #E5EBEF;
                            border: 1px solid #E5EBEF;
                            color: #004065;
                        }

                    
                        &__name, &__email, &__phone{
                            color: #004065;
                            font-size: 18px;
                        }
                        .textAreaPara{
                            @media (min-width: 1025px){
                                position: relative;
                                left: -15px;
                            }
                        }
                        &__name__descriptionJob{
                            color: #004065;
      
                            &__title{
                               font-size: 20px;
                               font-weight: 700;
                            }
                            &__job{
                               font-size: 20px;
                               text-decoration: underline;
                               margin: 10px 0 0 0;
                            }
                            &__description{
                               font-size: 18px;
                               white-space: pre-line;
                            }
                            
                        }
                        .formToComplete{
                            @media (min-width: 1025px){
                               width: 45%;
                               margin: 0 auto;
                               margin-bottom: 3rem;
                            }
                            .inputDiv{
                                @media (min-width: 1025px){
                                    width: 100%;
                                    margin: 15px 0;
                                    display: flex;
                                    flex-direction: row;
                                  
                                }
                                p {
                                    @media (min-width: 1025px){
                                        width: 100px;
                                        width: 30%;
                                        margin-left: 0 !important;
                                        text-align: end;
                                        margin-right: 10px;
                                    }
                                }
                                input{
                                    @media (min-width: 1025px){
                                        width: 70%;
                                    }
                                }
                                // .inputModified{
                                //     @media (min-width: 1025px){
                                //         margin: 0;
                                //         width: 100px;
                                //     }
                                // }
                                #cv_letter, #motivational_letter{
                                    width: 70%;
                                    background-color: #C1922D;
                                    opacity: 0;
                                    width: 100%;
                                    position: absolute;
                                    height: 100%;
                                }
                                .custom-file-upload {
                                    border-radius: 5px;
                                    position: relative;
                                    border: 1px solid #ccc;
                                    display: inline-block;
                                    padding: 6px 12px;
                                    cursor: pointer;
                                    color: red;
                                    width: 100%;
                                    background-color: #C1922D;
                                    text-decoration-color: #fff;
                                    color: #fff;
                                    font-size: 20px;
                                    text-align: center;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    @media (min-width: 1025px){
                                        width: 70%;
                                    }
                                }
                                .textButtonUpload{
                                    text-decoration: underline;
                                    width: 65%;
                                    text-align: end;
                                   
                                    @media (min-width: 1025px){
                                        width: 55%;
                                    }
                                }
                                .arrow_up{
                                    position: relative;
                                    // top: -3px;
                                    width: 20px;
                                    left: 7px;
                                    @media (min-width: 1025px){
                                        left: 0;
                                    }
                                }
                            }
                        }
                        .textArea{
                            padding: 0;
                            height: 150px;
                            text-align: start;
                            @media (min-width: 1025px){
                                width: 70%;
                                text-align: start;
                                // margin-right: -6%;
                                position: relative;
                                // right: -4%;
                            }
                        }
                    }
                }

                &__modal__form__agreements{
                    .makeSomeSpaceHere{
                        display: inline;
                        margin-right: 30px;
                    }
                        position: relative;
                        display: flex;
                        cursor: pointer;
                        text-align: center;
                        // margin-inline: calc(1.5rem + 18px);
                        font-family: "Roboto Condensed", sans-serif;
                        text-align: start;
                        user-select: none;
                        &__checkbox {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                            &:checked {
                                ~ {
                                    .q10__content__block__content__modal__form__agreements__checkmark {
                                        background-color: #fff;
                                        &:after {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            .q10__content__block__content__modal__form__agreements__checkbox {
                                ~ {
                                    .q10__content__block__content__modal__form__agreements__checkmark {
                                        background-color: #fff;
                                    }
                                }
                            }
                        }
                        &__checkmark {
                            position: absolute;
                            // left: -1.5rem;
                            height: 16px;
                            width: 16px;
                            background-color: #fff;
                            border: 2.5px solid #C1922D;
                            border-radius: 6px;
                            top: -4px;
                            top: 0px;
                            @media (min-width: 1025px){
                                margin-left: 0%;
                            }
                            @media (min-width: 1025px){
                                width: 14px;
                                height: 14px;
                            }
                            @media (min-width: 1200px){
                                width: 18px;
                                height: 18px;
                            }
                            &:after {
                                content: "";
                                background-color: #C1922D;
                                position: absolute;
                                display: none;
                                justify-content: center;
                                // align-items: center;
                                color: #000000;
                                font-weight: 900;
                                font-size: 13px;
                                font-family: "Roboto", sans-serif;
                                width: 100%;
                                height: 100%;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                border-radius: 2px;
                            }
                        }
                        &__text {
                            color: #004065;
                            font-size: 14px;
                            @media (min-width: 1025px){
                                margin-left: 0%;
                            }
                            @media (min-width: 1200px){
                                font-size: 1vw;
                            }
                            @media (min-width: 1800px){
                                font-size: 0.8vw;
                            }
                            // @media (min-width: 2400px){
                            //     font-size: 20px;
                            // }
                            .data-security {
                                color: var(--btn_color);
                                text-decoration: none;
                            }
                        }
                        .addSpaceStart{
                            display: inline;
                            margin-right: 30px;
                        }
                    
                }
                
                }

            }
            .confirm_text{
                text-align: start;
                padding: 15px 15px;
                font-size: 22px;
                color: #004065;  
                
            }
        }
        
    }
