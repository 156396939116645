:root {
    // ! Colors
    --header_top_color: #7495c3;
    --header_nav_color: #064266;
    --primary_color:  #f8f9fa;;
    --secondary_color: #dce4f0;
    --text_color: #064266;
    --btn_color: #C1922D;
    --btn_color_hover: #c28400;
    --goToBtn_color: #dce4f0;
    --goToBtnArrow_color: #064266;
    --cookies_color: #f8f9fa;
    --cookies_box_shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

    // ! Spacing
    --header_height: 214px;
    --footer_height: 69px;
}
