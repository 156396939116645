.q10__header {
    position: relative;
    width: 100%;
    background-color: var(--header_nav_color);
    box-sizing: border-box;
    z-index: 100;
    
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;

        height: 80px;

        @media  (min-width: 768px) {
            height: 106px;
            width: 750px;
        }
        @media (min-width:990px){
            height: 62px;
        }
        @media (min-width: 992px){
            width: 970px;
        }
        @media (min-width: 1200px){
            width: 1250px;
        }
        @media (min-width: 1500px){
            width: 94%;
        }
        @media (min-width: 1800px){
            width: 100%;
        }
        // @media (min-width: 2400px){
        //     width: 96%;
        // }
       
       
        
        &__group {
            position: relative;
        }
        &__lang {
            display: inline-block;
            color: var(--text_color);
            color: #fff;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            // display: none;
            gap: 0.5rem;
            @media (min-width: 990px) { 
                
                padding-right: 3rem;
                display: flex;
            }
            @media (min-width: 1800px){
                padding: 0 120px;
            }
            a {
                color: var(--text_color);
                color: #fff;
                //text-decoration: none;
                font-weight: 100;
                font-family: "Roboto Condensed", sans-serif;
            }

            &__name {
                position: relative;
                color: #fff;
                font-family: "Ubuntu", sans-serif;
                font-weight: 500;
                font-size: 20px;
                left: 11px;
                bottom: -2px;

                @media (min-width: 1025px){
                display: none;
                position: absolute;
                left: 50%;
                bottom: -32px;
                transform: translateX(-50%);
                z-index: 102;
                color: #000;
                font-size: 15px;
                // font-weight: 700;
                }
                
               
            }
            .dropdown-item:hover &__name{
                display: block;
            }

            &__icon {
                .icon {
                    width: 40px;
                    height: auto;
                    display: block;
                }
                &:hover ~ p {
                    display: block;
                }
            }
            .dropdown {
                position: relative;
                display: inline-block;
              }
              
              .dropdown-content {
                display: none;
                position: absolute;
                z-index: 1;
                box-shadow: rgb(0 0 0 / 35%) 0px 2px 0px;
                background-color: #064266;
                margin-top: -2px;
                width: 163.5px;
                @media (min-width: 1025px){
                    display: flex;
                    position: relative;
                    box-shadow: none;
                }
              }
              
              .dropdown-content a {
                display: flex;
                // padding: 8.5px;
                width: 50px;
                margin-left: 3px;
                
              }
              #selected{
                display: flex;
                background-color: #064266;
                padding: 10px;
                font-size: 20px;
                cursor: pointer;
                @media (min-width: 1025px){
                    display: none;
                }
              }

              .countryImage{
                display: inline;
                width: 35px;
                margin-right: 10px;
              }
              .countryText{
                display: inline;
                position: relative;
                color: #fff;
                bottom: -2px;
                font-size: 19px;
                font-family: "Roboto", sans-serif;
              }
              .arrow_down{
                margin-left: 10px;
                width: 15px;
                height: 12px;
                position: relative;
                bottom: -11px;
              }
          
              .dropdown-item{
                display: flex;
                // border: 2px solid #000;
              }

              
        }

        &__menu {
            @media (max-width: 768px){
                display: none;
                // display: flex;
                position: absolute;
                flex-direction: column;
                right: 0;
                top: 79px;
                z-index: 105;
                text-align: end;
                background-color: #064266;
                padding: 10px;
                padding-right: 20px;
                a{
                    text-decoration-color: #fff;
                    color: #fff;
                    font-size: 19px;
                    padding: 5px 0;
                }
            }
            @media (min-width: 768px){
                color: var(--text_color);
                justify-content: center;
                align-items: center;
                padding: 0 20px 0 0;    
                color: #040505;
                text-decoration: none;
                display: flex;
                
                & > * {
                    text-decoration: none;  
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.4px;
                    //text-transform: capitalize;
                    // @media (min-width: 2400px){
                    //     font-size: 22px;
                    // }
                }
            }
        }
        &__box {
            margin-bottom: -7.5px;
            display: flex;
            padding-left: 15px;
            // max-width: 50%;
            width: 180px;
            @media (min-width: 768px) {
                height: 36.35px
            }
            &__picture{
                background: white;
                border-radius: 35px;
                width: 100%;
                border: 6px solid white;
            }
            
        }
        &__mobile_menu {
            margin-right: 20px;
            // display: none;
            .dropdown_menu{
                display: flex;
                .white_text{
                    font-size: 19px;
                    color: #fff;
                    padding-right: 5px;
                    font-family: "Roboto", sans-serif;
                }
                .white_dots_vertical{
                    text-decoration: none;
                    .white_dot {
                        width: 5.5px;
                        height: 5.5px;
                        background-color: white;
                        margin: 2.5px;
                        position: relative;
                        bottom: -3px;
                        border-radius: 9999px;
                        list-style-type: none;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                .white_dots_horizontal{
                    display: flex;
                    text-decoration: none;
                    .white_dot {
                        width: 5.5px;
                        height: 5.5px;
                        background-color: white;
                        margin: 2.5px;
                        position: relative;
                        bottom: -3px;
                        left: 10px;
                        border-radius: 9999px;
                        list-style-type: none;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                .close_x_vertical{
                    display: none;
                    width: 17px;
                    position: relative;
                    bottom: -1px;
                }
                .close_x_horizontal{
                    display: none;
                    width: 17px;
                    position: relative;
                    bottom: -1px;
                    left: 15px;
                }
            }
            
               
            @media (min-width: 769px){
                display: none;
            }
           
        }
 
    }

    &__popup {
        overflow: hidden;
        display: none;
        left: 0;
        opacity: 1;
        right: 0;
        transition: opacity 0.25s ease-out;
        position: fixed;
        bottom: 0;
        z-index: 999;
        top: 0;

        &__menu {
            background: #2a4891;
            & > * {
                color: white;
                text-decoration: none;
                display: flex;
                flex-wrap: wrap;
                line-height: 1;
                justify-content: flex-start;
                margin: 0;
                padding: 1rem;
                letter-spacing: -0.0375em;
                font-size: 15px;
                border-bottom: 1px solid rgba(241,241,241,0.22);
            }
        }
    }
    a.vertical-line:after{
        @media (min-width: 768px) {
        content: '|';
        margin-left: 0.5rem;
        }
    }
    a.header:before{
        @media (min-width: 768px) {
            content: '';
            margin-right: 0.5rem;
        }
    }
    // a.header:last-child:after{
    //         content: '';
    // }
}

.q10__navbar{
    background-color: white;

    &__logo {
        width: 100%;
        padding: 2.3rem 0 2rem 0;
        position: relative;
        .logo_img {
            display: block;
            height: auto;
            margin: 0 auto;
            height: 50px;
            width: 250px;
        }
        .portal{
            position: absolute;
            right: 0;
            top: 2px;
            color: #064266;
            background-color: #9ABCDB;
            padding: 5px;
            border-radius: 17px;
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            @media (min-width: 768px){
                top: 15px;
                padding: 7px;
                font-size: 20px;
                display:none;
            }
            @media (min-width: 1800px){
                padding: 10px;
            }
            img{
                height: 10px;
                width: 10px;
                @media (min-width: 768px){
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
    &__logo__mobile {
        // width: 100%;
        // padding: 2.3rem 0 2rem 0;
        // position: relative;
        margin-left: 2rem;
        .logo_img {
            display: block;
            height: auto;
            margin: 0 auto;
            height: 50px;
            width: 250px;
        }
        .portal{
            color: #064266;
            background-color: #9ABCDB;
            padding: 5px;
            border-radius: 17px;
            display: flex;
            align-items: center;
            img{
                height: 10px;
                width: 10px;
                @media (min-width: 768px){
                    height: 15px;
                    width: 15px;
                }
            }
            @media (max-width: 768px){
                display:none;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: center;
        gap: 1rem;
        font-size: 15px;
        // @media (max-width: 768px) {
            padding-bottom: 10px;
            box-shadow: 1px 5px 7px -4px #707070;
            // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        // }
        & > * {
            color: var(--text_color);
            text-decoration: none;
            text-align: center;
        }
        @media (min-width: 768px){
            justify-content: center;
            font-size: 16px;
            gap: 5rem;
        }
    }
    
}

.q10_header_logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: black;
    padding: 1rem;
    .button {
        text-decoration: none;
    }
}
.active {
    display: block !important;
    left: 0;
    opacity: 1;
    right: 0;
    transition: opacity 0.25s ease-out;
    position: relative;
    z-index: 9999;
    background-color: #0093FF;
}

.active-lang {
    border-color: #dedfdf;
    border-style: solid;
    border-width: 0.1rem 0 0 0;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
}

.breadcrumbs{
    background: rgb(243, 245, 247);
    // overflow: hidden;
    position: relative;
    padding: 15px 15px 20px 15px;
    margin-bottom: 50px;
    &_slugs{
       
        margin: 0 auto;
        margin-top: 5px;
        
        @media (min-width: 768px) {
            text-align: left;
            padding-left: 15px;
            letter-spacing: 0.4px;
            // padding: 0rem 18%;
            // float: left;
            // clear: both;
                   
        }
        &_item{
            text-transform: capitalize;
            font-size: 16px;
            color: #333333;
        }
        @media  (min-width: 768px) {
            width: 750px;
        }
        @media (min-width: 992px){
            width: 970px;
        }
        @media (min-width: 1200px){
            width: 1170px;
        }
        .last{
            color: #6f95f7;
        }
    }
    * > li {
    display: inline-block;
    }
    * > li + li:before {
    color: #10aedf;
    content: ">";
    font-size: 16px;

    }
    * > .active {
    color: #777;
    }

}
