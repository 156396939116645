.q10__footer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 13px 10px;
    height: 114px;
    gap: 1.5rem;
    // box-shadow: 0px 0 10px rgb(0 0 0 / 80%);
    background: #064266;
    border-top: 1.5px solid #fff;
    @media (min-width: 768px) {
        padding: 1.5rem 4rem;
        flex-direction: row;
        justify-content: space-between;
        gap: 0rem;
        bottom: 0;
        position: sticky;
        // background-color: var(--primary_color);
    }
    @media (min-width: 1025px){ 
        border-top: 1.5px solid #0F4F7E;
        height: 69px;
    }
    @media (min-width: 1200px){
      
        justify-content: center;
        padding: 13px 10px;
        position: absolute;

    }
    
    
    &__icons{
        display: none;
        @media (min-width: 768px){
            width: 20%;
            display: flex;
            flex-direction: row;
            margin: 15px 0;
            gap: 15px;
            justify-content: flex-end;
        }
        @media (min-width: 1200px){
            gap: 5px;
        }
        
        img{
            display: block;
            height: auto;
            width: 35px;
            @media (min-width: 1200px){
                width: 25px;
            }
            @media (min-width: 1800px){
                width: 30px;
            }
            @media (min-width: 2400px){
                width: 40px;
            }
        }
    }
    
    &__nav {
        display: flex;
        gap: 1.5rem;
        .link {
            text-decoration: none;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            color: #fff;
            @media (min-width:2400px){
                font-size: 25px;
                font-size: 16px;
            }
        }
        @media (min-width: 768px){
            width: 20%;
        }

        a{
            font-size: 18px;
            @media (min-width: 1200px){
                 font-size: 16px;
                 text-align: center;
             }
            // @media (min-width: 1500px){
            //      font-size: 18px;
            //  }
            // @media (min-width: 1800px){
            //     font-size: 20px;
            //  }
            //  @media (min-width: 2400px){
            //     font-size: 24px; 
            // }
        }
        
       
    }

    &__visit{
        color: #040505;
        text-align: center;
        .rights{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: 300;
            &__link {
                font-size: 15px;
                font-family: "Roboto", sans-serif;
                font-weight: 300;
                color: #fff;
                width: 55%;
                @media (min-width: 1200px){
                   text-align: center;
                   font-size: 12px;
                   font-size: 16px;
                }
                // @media (min-width: 1500px){
                //    font-size: 14px;
                // }
                // @media (min-width: 1800px){
                //     font-size: 16px;
                // }
                // @media (min-width: 2400px){
                //     font-size: 22px;
                // }

            }
            
        }
        @media (min-width: 768px){
            text-align: start;
            width: 60%;
            .rights{
                text-align: start;
                font-weight: 300;
                justify-content: center;
            }
                
            }
        
    }
    
}
