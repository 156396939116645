.custom__btn{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #0F4F7E;
    &__shape{
        // background-color: #004065;
        img{
            position: relative;
            bottom: -7px;
            width: 70px;
        }
    }
}
.overlay{
    background-color: #004065;
    // margin: 0 -15px; //poate pe desktop e nevoie
    @media (min-width: 768px){
       margin: 0 0px; 
       width: 100%;
    }
}
.q10__content__block {
    padding: 0 15px;
    width: 100%;
    position: relative;
    //padding-bottom: 55px;
    .q10__content__block__content {
        color: var(--text_color);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 0 1rem;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        &__item {
            flex: 1;
            margin-bottom: 2rem;
            text-align: justify;
            @media (min-width: 768px) {
                margin-bottom: 0rem;
            }
            & > * {
                margin-bottom: 1.5rem;
            }
            &__steps {
                display: flex;
                align-items: center;
                .step {
                    font-size: 22px;
                }
                .step_no {
                    font-size: 16px;
                    background-color: var(--goToBtnArrow_color);
                    color: var(--goToBtn_color);
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 0.5rem;
                }
            }
            &__images {
                .image {
                    display: block;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
    &__content {
        // padding: 2rem 10%;
        color: white;
        position: relative;
        &__form {
            margin: 0 auto;
            &__group {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 3rem;
                text-align: start;
                padding: 0 30px;
                @media (min-width:1025px){
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    padding: 30px 30px;
                    align-items: flex-start;
                }
                @media (min-width:1200px){
                   padding: 50px 30px 10px 30px;
                }
                .firstHalf{
                    @media (min-width: 1025px){
                        width: 45%;
                       margin-right: 4.9%;
                    }
                    @media (min-width: 1200px){
                        width: 31%;
                    }
                    @media (min-width: 1800px){
                        width: 27%;
                        margin-right: 4.9%;
                    }
                    @media (min-width: 2400px){
                        width: 27%;
                        margin-right: 4.9%;
                    }
                }
                .betweenHalfs{
                    @media (min-width: 1025px){
                        width: 0.5px;
                        height: 570px;
                        background-color: #fff;
                        margin-top: 10px;
                    }
                }
                .secondHalf{
                    @media (min-width: 1025px){
                        width: 45%;
                        margin-left: 4.9%;
                        text-align: start;
                        justify-content: flex-start;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        // margin-top: -19.5%;
                    }
                    @media (min-width: 1200px){
                        width: 31%;
                    }
                    @media (min-width: 1800px){
                        width: 27%;
                        margin-right: 4.9%;
                    }
                    @media (min-width: 1800px){
                        width: 27%;
                        margin-right: -0.1%;
                    }
                }
                
                &__title {
                    text-align: center;
                    font-size: 20px;
                    margin-bottom: 0.6rem;
                    font-weight: 700;
                    margin-top: 15px;
                    @media (min-width: 1200px){
                        margin-left: 20%;
                       font-size: 18px; 
                    }
                    @media (min-width: 1500px){
                       font-size: 20px; 
                    }
                    @media (min-width: 1800px){
                        font-size: 24px; 
                    }
                    // @media (min-width: 2400px){
                    //     font-size: 26px; 
                    // }
                }
                &__descriptionDiv{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .description{
                        text-align: start;
                        font-size: 16px;
                        font-weight: normal;
                        margin: 0 -20px;
                        margin-top: 5px;
                        margin-bottom: 10px;
                        @media (min-width: 1025px){
                            margin-left: 15%;
                        }
                        @media (min-width: 1200px){
                            font-size: 14px;
                        }
                        @media (min-width: 1500px){
                            font-size: 16px;
                        }
                        @media (min-width: 1800px){
                            font-size: 18px;
                        }
                        // @media (min-width: 2400px){
                        //     font-size:21px;
                        // }
                    }
                }
                &__name,&__email,&__phoneNumber,&__message{
                    font-size: 16px;
                    margin: 4px 0;
                    @media (min-width:1025px){
                        width: 15%;
                        margin: 5px 0;
                    }
                    @media (min-width:1200px){
                        width: 15%;
                        margin: 5px 0;
                        font-size: 11px;
                    }
                    @media (min-width:1500px){
                        font-size:12px;
                    }
                    @media (min-width:1800px){
                        font-size:14px;
                    }
                    @media (min-width:2400px){
                        font-size: 15px
                    }
                }
               
                &__phone{
                    font-size: 10px;
                    text-align: start;
                    width: 100%;
                    margin: 10px 0;
                    @media (min-width: 1025px){
                        margin-left: 15%;
                    }
                    @media (min-width: 1200px){
                        margin-left: 15%;
                        font-size: 12px;
                    }
                    @media (min-width:1800px){ 
                        font-size: 12px;
                    }
                }
                .infoMust{
                    @media (min-width: 1025px){
                        margin-left: 30%;
                        font-size: 12px !important;
                    }
                    @media (min-width: 1200px){
                        margin-left: 30%;
                        font-size: 12px !important;
                    }
                }
                &__subtitle {
                    text-align: center;
                    font-size: 18px;
                    margin-bottom: 1rem;
                    font-weight: 400;
                }
                &__under_button {
                    text-align: center;
                    font-size: 18px;
                    margin-top: 1rem;
                    font-weight: 400;
                }
                &__div {
                    padding-top: 0.2rem;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    &__button {
                        text-decoration: underline;
                        cursor: pointer;
                        background-color: #C1922D;
                        color: #fff;
                        font-family: "Roboto", sans-serif;
                        font-weight: bold;
                        border-radius: 0;
                        font-size: 12px;
                        // width: 200px;
                        width: 100%;
                        padding: 10px;
                        display: block;
                        // margin: auto;
                        text-decoration: none;
                        border: none;
                        border-radius: 3px;
                        @media (min-width: 1025px){
                            text-decoration: underline;
                            cursor: pointer;
                            font-size: 20px;
                            font-weight: 700;
                            margin-left: 15%;
                            width: 70%;
                            margin-top: 2rem;
                        }
                    }
                }
                &__inputDiv {
                    width: 100%;
                    @media (min-width: 1025px){
                        display: flex;
                        flex-direction: row;
                        margin: 15px 0;
                    }
                }
                &__name{
                    
                }
                &__input {
                   
                    border-bottom: solid 2px #cbcbcb;
                    border-bottom: solid 2px #fff;
                    border-radius: 0;
                    background: transparent;
                    background: #fff;
                    font-family: "Roboto", sans-serif;
                    font-size: 20px;
                    color: #004065;
                    font-weight: normal;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    padding: 1px 15px;
                    text-align: center;
                    width: 100%;
                    // width: 300px;
                    border-radius: 5px;
                    @media (min-width:1025px){
                        width: 85%;
                    
                }
            }
            .textArea{
                height: 10  0px;
                text-align: start;
            }
                &__error_message {
                    color: red;
                    @media (min-width:1025px){
                        margin-left: 60px;
                    }
                }
                &__addWHiteBar{
                    border-bottom: 1.5px solid #fff;
                    display: flex;
                    justify-content: center;
                    width: 90%;
                    margin: 25px auto;
                    @media (min-width: 1025px){
                        display: none;
                    }
                }
                &__contactInfo{
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    width: 90%;
                    margin: 0 auto;
                    @media (min-width: 1025px){
                        width: 50%;
                        text-align: start;
                        margin-top: 15px;
                        margin: 0;
                    }
                    @media (min-width: 1025px){
                        font-size: 18px;
                        width: 80%;
                    }
                    @media (min-width: 1200px){
                        font-size: 18px;
                        width: 80%;
                    }
                    @media (min-width: 1500px){
                        font-size: 20px; 
                     }
                    @media (min-width: 1800px){
                        width: 70%;
                        font-size: 24px; 
                    }
                    // @media (min-width: 2400px){
                    //     width: 50%;
                    //     font-size: 26px; 
                    // }
                }
                &__box{
                    display: flex;
                    flex-direction: column;
                    margin-top: 10%;
                    margin-bottom: 10%;
                }
                
                &__contactPhoneNumber{
                    font-size: 16px;
                    font-family: "Roboto", sans-serif;
                    font-weight: 500;
                    margin: 10px 0;
                    text-align: center;
                    color:white;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    @media (min-width: 1025px){
                        position: relative;
                        left: 18px;
                        margin-top: 10%;
                    }
                    @media (min-width: 1025px){
                        font-size: 12px;
                        left: 20px;
                    }
                    @media (min-width: 1200px){
                        font-size: 14px;
                        left: 0px;
                    }
                    @media (min-width: 1800px){
                        font-size: 18px;
                    }
                    // @media (min-width: 2400px){
                    //     font-size:22px;
                    // }
                }
                .iconContactPhoneNumber{
                    width: 25px;
                    margin-right: 20px;
                    position: relative;
                    bottom: -4px;
                    @media (min-width: 1025px){
                        width: 20px;
                        bottom: -6px;
                    }
                    @media (min-width: 1200px){
                        width: 20px;
                        bottom: -6px;
                    }
                    @media (min-width: 1200px){
                        width: 20px;
                        bottom: -4px;
                    }
                    @media (min-width: 1800px){
                        width: 30px;
                        bottom: -4px;
                    }
                }
                &__contactEmail{
                    font-family: "Roboto", sans-serif;
                    font-size: 16px;
                    font-weight: 300;
                    text-align: center;
                    color:white;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    @media (min-width: 1025px){
                        margin-bottom: 10%;
                        font-size: 12px;
                    }
                    @media (min-width: 1200px){
                        margin-bottom: 10%;
                        font-size: 14px;
                    }
                    @media (min-width: 1800px){
                        font-size: 18px;
                    }
                    // @media (min-width: 2400px){
                    //     font-size:22px;
                    // }
                }
                .iconContactEmail{
                    width: 25px;
                    margin-right: 20px;
                    position: relative;
                    bottom: -4px;
                    @media (min-width: 1025px){
                        width: 20px;
                        bottom: -6px;
                    }
                    @media (min-width: 1200px){
                        width: 20px;
                        bottom: -6px;
                        left: -3px;
                    }
                    @media (min-width: 1200px){
                        width: 20px;
                        bottom: -4px;
                        left: 0px;
                    }
                    @media (min-width: 1800px){
                        width: 30px;
                        bottom: -4px;
                        left: 0px;
                    }
                }
                
                &__workingPoint{
                    display: flex;
                    flex-direction: row;
                    font-family: "Roboto", sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 15px;
                    justify-content: center;
                    @media (min-width: 1025px){
                        font-size: 18px;
                        justify-content: flex-start;
                    }
                    @media (min-width: 1200px){
                        font-size: 18px;
                    }
                    @media (min-width: 1500px){
                        font-size: 20px; 
                     }
                    @media (min-width: 1800px){
                        font-size: 24px; 
                    }
                    // @media (min-width: 2400px){
                    //     font-size: 26px; 
                    // }
                }
            
                &__contactPinLocationDiv  {
                   margin: 0 20px;
                   @media (min-width: 1025px){
                    margin: 0;
                   }
                }
                &__contactPinLocation{
                    font-family: "Roboto", sans-serif;
                    display: flex;
                    flex-direction: row;
                    font-size: 16px;
                    font-weight: 300;
                    
                    
                }
                &__contactPinLocation:first-of-type{
                    font-family: "Roboto", sans-serif;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
                .iconContactPinLocation{
                    display: block;
                    width: 25px;
                    margin-right: 10px;
                    position: relative;
                    bottom: -1px;
                    @media (min-width: 1200px){
                        width: 20px;
                    }
                }
                &__contactPinLocationParagraph{
                    display: block;
                    font-size: 16px;
                    color:white;
                    @media (min-width: 1200px){
                        font-size: 15px;
                    }
                    @media (min-width: 1500px){
                        font-size: 16px;
                    }
                    @media (min-width: 1800px){
                       font-size: 18px;
                    }
                    // @media (min-width: 2400px){
                    //     font-size:20px;
                    // }
                }

            }
        }
        &__modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.85);
            display: flex;
            justify-content: center;
            z-index: -1;
            opacity: 0;
            transition: all 250ms ease-in-out;
            &.active {
                z-index: 10;
                opacity: 1;
            }
            &__form {
                position: relative;
                background-color: var(--header_nav_color);
                color: var(--goToBtnArrow_color);
                margin: auto;
                padding: 1.5rem 2.5rem;
                border-radius: 4px;
                width: 95%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 250ms ease-in-out;
                transform: scale(0);
                &.active {
                    transform: scale(1);
                }
                @media (min-width: 768px) {
                    width: 65%;
                    max-width: 700px;
                }
                & > * {
                    margin-bottom: 1.5rem;
                    text-align: center;
                }
                &__close {
                    position: absolute;
                    top: 1%;
                    right: 4%;
                    color: grey;
                    font-size: 35px;
                    cursor: pointer;
                    line-height: 1;
                }
                &__phone {
                    margin-inline: auto;
                    max-width: 285px;
                    border: 2px solid #e46617;
                    line-height: 1.5;
                    padding: 0.5rem 2rem;
                    font-size: 16px;
                    font-family: "Roboto Condensed", sans-serif;
                    border-radius: 4px;
                    outline: none;
                }
                &__prefix {
                    margin-inline: auto;
                    max-width: 285px;
                    border: 2px solid #e46617;
                    line-height: 1.5;
                    padding: 0.5rem 0.5rem;
                    font-size: 16px;
                    font-family: "Roboto Condensed", sans-serif;
                    border-radius: 4px;
                    outline: none;
                    margin: 5px 5px 5px 5px;
                }
                &__agreement {
                    position: relative;
                    display: flex;
                    cursor: pointer;
                    text-align: center;
                    // margin-inline: calc(1.5rem + 18px);
                    font-family: "Roboto Condensed", sans-serif;
                    text-align: start;
                    user-select: none;
                    &__checkbox {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                        &:checked {
                            ~ {
                                .q10__content__block__content__modal__form__agreement__checkmark {
                                    background-color: #fff;
                                    &:after {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        .q10__content__block__content__modal__form__agreement__checkbox {
                            ~ {
                                .q10__content__block__content__modal__form__agreement__checkmark {
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                    &__checkmark {
                        position: absolute;
                        top: 2px;
                        // left: -1.5rem;
                        height: 16px;
                        width: 16px;
                        background-color: #fff;
                        border: 2.5px solid #fff;
                        border-radius: 6px;
                        top: 0px;
                        @media (min-width: 1025px){
                            margin-left: 15%;
                        }
                        @media (min-width: 1025px){
                            width: 14px;
                            height: 14px;
                        }
                        &:after {
                            content: "";
                            background-color: #C1922D;
                            position: absolute;
                            display: none;
                            justify-content: center;
                            align-items: center;
                            color: #000000;
                            font-weight: 900;
                            font-size: 13px;
                            font-family: "Roboto", sans-serif;
                            width: 90%;
                            height: 90%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 2px;
                        }
                    }
                    &__text {
                        font-size: 14px;
                        @media (min-width: 1025px){
                            margin-left: 15%;
                        }
                        @media (min-width: 1200px){
                            font-size: 12px;
                        }
                        @media (min-width: 1800px){
                            font-size: 14px;
                        }
                        // @media (min-width: 2400px){
                        //     font-size:20px;
                        // }
                        .data-security {
                            color: var(--btn_color);
                            text-decoration: none;
                        }
                    }
                    .addSpaceStart{
                        display: inline;
                        margin-right: 30px;
                    }
                }
                &__submit {
                    font-family: "Roboto", sans-serif;
                    outline: none;
                    border: 2px solid #e46617;
                    padding: 0.5rem 1rem;
                    font-weight: bold;
                    cursor: pointer;
                    background-color: transparent;
                    line-height: 1.5;
                    border-radius: 4px;
                    font-size: 16px;
                    color: var(--goToBtnArrow_color);
                }
            }
        }
    }
}

.q10__content__dynamic_blocks {
    //padding-block: 1rem;
    // padding-inline: 5%;
    // background-color: black;
    min-height: auto !important;
    @media (min-width: 768px) {
        // padding-inline: 15%;
    }
    &__block {
        margin-bottom: 2.5rem;
        text-align: center;
        position: relative;
        font-family: "Alata", sans-serif;
        &__title {
            font-weight: 700;
            font-size: 34px;
            line-height: 45px;
            text-transform: none;
            letter-spacing: -2.3px;
            color: #223972;
        }
        &__subtitle {
            margin-bottom: 1rem;
            color: white;
            font-size: 20px;
            font-weight: 500;
        }
        &__paragraph {
            line-height: 1.5;
            max-height: 0;
            overflow: hidden;
            transition: max-height 1s ease-out;
            transition: 2s ease-in-out;
        }
        &__images {
            .image {
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }
}

.q10__content__dynamic {
    
    // padding: 1rem;
    @media (min-width: 768px) {
        flex-direction: row;

    }
    &__title {
        font-weight: 700;
        font-size: 34px;
        line-height: 45px;
        text-transform: none;
        letter-spacing: -2.3px;
        color: #223972;
        position: relative;
        bottom: -13px;
        @media (min-width: 1200px){
            bottom: -21px;
            font-size: 26px;
        }
        @media (min-width: 1500px){
            font-size: 30px;
        }
        @media (min-width: 1800px){
            font-size: 34px;
            bottom: -30px;
        }
        @media (min-width: 1800px){
            bottom: -38px;
        }
        // @media (min-width: 2400px){
        //     font-size: 41px;
        //     bottom: -32px; 
        // }
    
    &:after {
        border-bottom: 1px solid #e4e4e4;
        content: '';
        width: 100%;
        display: block;
        padding-bottom: 1.5rem;
      }
    }

    &__description {
        padding-top: 1.5rem;
        font-size: 14px;

    }
    &__info{
        &__box{
            padding: 1rem;
            color: #5e7f96;
            border-color: #cfebfe;
            background-color: #dff2fe;
            font-size: 14px;
            display: flex;
            gap: 1rem;
            align-items: center;
            &__circle{
                color: #56b0ee;
                font-size: 1.7em;
            }
        }
    }
    
    &__iconImage{
        width: 30px;
    }
    &__image_box {
        position: relative;
        // gap: 0 1rem;
        margin: 1.5rem 0;
        width: 100%;
        &__title {
            width: 100%;
            display: block;
            // margin: 1rem 0;
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            .text {
            }
        }
        &__link {
            text-decoration: none;
            
        }
        &__images {
            // padding-top: 0.5rem;
            // padding-bottom: 0.5rem;
            padding-top: 1.5rem;
            .image {
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }
    .inline{
        display: flex;
        flex-direction: row;
        @media (min-width: 1025px){
            margin: 0 80px;
        }
        @media (min-width: 1800px){
            margin-top: 15px;
        }
        @media (min-width: 2400px){
            margin-top: 40px;
        }
    }
    .wysiwyg{
        @media (max-width: 1024px){
            padding: 0 15px;
        }
        @media (min-width: 1025px){
            margin: 0 80px;
            font-size: 14px;
        }
        @media (min-width: 1200px){
            font-size: 16px;
        }
        @media (min-width: 1500px){
            font-size: 18px;
        }
        @media (min-width: 1800px){
            font-size: 20px;
        }
        // @media (min-width: 2400px){
        //     font-size: 28px;
        // }
    }
    &__images{
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        max-width: 100%;
        &__content{
            height: 100%;  
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title{
                font-weight: 700;
                font-size: 34px;
                line-height: 45px;
                text-transform: none;
                letter-spacing: -2.3px;
                color: #223972;
                
            }
            &__image{
                display: flex;
                justify-content: center;
                background-color: #3574A9;
                .image{
                    width: 100%;
                    height: 100%;
                    // @media (min-width: 1025px){  
                    //     height: 659px;
                    // }
                    // @media (min-width: 1528px){
                    //     width: 1528px;
                    // }
                }
            }
            &__iconImage{
                display: flex;
                align-items: center;
                justify-content: center;
                .image{
                 width: 80px;
                 height: auto;  
                    @media (min-width: 1800px){
                        width: 100px;
                    } 
                    @media (min-width: 2400px){
                        width: 120px;
                    } 
                }
                
            }
        }
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
}


.q10__content__title_section {
    padding: 15px 15px;
    &__block {
        color: #004065;
        margin-bottom: 2.5rem;
        &__title {
            // margin-bottom: 1rem;
            font-size: 20px;
        }
        &__subtitle {
            // margin-bottom: 1rem;
        }
        &__paragraph {
            line-height: 1.5;
        }
    }
}

.mt-1{
    margin-top:1rem;
}

.box_back_button{
    background-color: #669AC9;
    display: inline-block;
    width: auto;
    height: 35px;
    margin-top: 10px;
    position: relative;
    padding: 5px;
    .arrow_left{
        width: 7px;
        margin: 0 3px 0 3px;
        color: #004065;
    }
    a{
        color: #004065;
        text-decoration: none;
     }
     .backButton{
        cursor: pointer;
    }
     
}
